import PropTypes from "prop-types"
import React from "react"

class AppendScript extends React.Component {

  constructor(props) {
    super(props);
   }

    componentDidMount() {
        const script = document.createElement("script");
        script.src = this.props.src;
        script.async = true;
        script.setAttribute("disabled", "disabled")
        script.setAttribute("rel", "noopener noreferrer")
        document.body.appendChild(script);
    }

    render() {
      return (null)
    }
  }

export default AppendScript