import React, { useEffect } from 'react';

function PaymentButton(props) {

    const scriptID = `${props.shortName}-payment-script`;
    
    useEffect(() => {
        appendScript()
    }, []);

    const appendScript = () => {
        let script = document.createElement("script");
        script.src = props.script;
        script.async = true;
        script.setAttribute("disabled", "disabled")
        script.setAttribute("rel", "noopener noreferrer")
        script.setAttribute("id", scriptID)
        document.getElementById(props.id + "-container").appendChild(script);
    };

    const updateScript = () => {
        setTimeout(() => {
            const form = document.getElementById("converge-form");
            const script = document.getElementById(scriptID);

            form.remove();
            script.remove();

            appendScript()

        }, 500)
    };

    // Must be <button> because converge script looks for a <button> element
    return (
        <div onClick={() => updateScript()} className="payment-container" id={props.id + "-container"}>
            <button className="standard-button contained" id={props.id}>
                {props.buttonText}
            </button>
        </div>
    )
}

export default PaymentButton