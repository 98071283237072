import React, { useEffect } from 'react';
import PropTypes from "prop-types";
import { graphql } from "gatsby"
import Layout from "../components/layout"
import { SharedStateProvider } from "../components/SharedState/PageSharedState"
import SEO from "../components/seo"


import Button from '@bit/azheng.joshua-tree.button';
import ButtonGroup from "@bit/azheng.joshua-tree.button-group"
import ButtonGroupMap from "../components/Button/ButtonGroupMap"
import ImageMeta from "../components/ImageMeta"
import MarkdownViewer from '@bit/azheng.joshua-tree.markdown-viewer';
import HorizontalTwoColumns from "../components/Bulma/HorizontalTwoColumns"
import PaymentButton from "../components/PaymentButton"
import SideCTA from "../components/CTA/SideCTA"
import AppendScript from "../components/AppendScript"

const Career = (props) => {

    const post = props.data.allUniquePagesJson.nodes[0];
    const language = "en";

    const linkToContactPage = "/" + props.data.contactLink.nodes[0].title;

    return (
        <SharedStateProvider>
            <Layout pageTitle="career-page" language={language}>
                <SEO
                    title={post.metaTitle} description={post.metaDescription} pathname={props.location.pathname}
                />

                <div className="columns top-section color-back">
                    <div className="column is-4"></div>
                    <div className="column">
                        <h1 style={{ marginTop: 0, marginBottom: 0 }}>{post.heading}</h1>
                        <Button
                            buttonText={language === "es" ? "ATRÁS" : "BACK"}
                            goBack
                        />
                    </div>
                    <div className="column is-4"></div>
                </div>

                <HorizontalTwoColumns
                    className="body-section joshua-tree-content"
                    leftColWidth={2}
                    rightColWidth={2}
                    middleColWidth={2}
                    leftColumn={
                        <div className="column">
                            <MarkdownViewer className="join-text" markdown={post.joinText} />
                            <div className="positions">
                                {
                                    post.positions.map((position) =>
                                        <Position
                                            key={position.heading}
                                            subheading={post.positionAvailableHeading}
                                            heading={position.title}
                                            blurb={position.blurb}
                                            buttons={position.buttons}
                                        />
                                    )
                                }
                            </div>
                        </div>
                    }
                    rightColumn={
                        <div className="column is-6">
                            <SideCTA
                                className="single-instruction-questions"
                                sideColumnIs={0}
                                subheading={language === "es" ? "Preguntas?" : post.sideCTA.subheading}
                                heading={
                                    language === "es"
                                        ? "Estamos Aquí Para Ayudar"
                                        : post.sideCTA.heading
                                }
                                paragraph={
                                    language === "es"
                                        ? "Póngase en contacto con nuestra oficina si tiene alguna pregunta o problema con estas instrucciones."
                                        : post.sideCTA.blurb
                                }
                                buttonText={language === "es" ? "Contáctenos" : post.sideCTA.buttonText}
                                buttonUrl={
                                    language === "es"
                                        ? "/es/comuniquese-con-nosotros/"
                                        : linkToContactPage
                                }
                            />
                        </div>
                    }
                />
            </Layout>
        </SharedStateProvider>
    )
}

export const pageQuery = graphql`
query CareerPage($title: String!) {
    allUniquePagesJson(filter: { title: { eq: $title } }) {
      nodes {
        heading
        metaDescription
        metaTitle
        searchHeading
        joinText
        positionAvailableHeading
        positions {
            blurb
            title
            buttons {
              button {
                appearance
                buttonText
                destination
                href
              }
            }
          }
        sideCTA {
            subheading
            heading
            blurb
            buttonText
        }
      }
    }
    contactLink: allUniquePagesJson(filter: {template: {eq: "contact-us"}}) {
        nodes {
          title
        }
      }
  }
  `;

export default Career


function Position({ subheading, heading, blurb, buttons }) {
    return (
        <div className="position">
            <h4>{subheading}</h4>
            <h3>{heading}</h3>
            <MarkdownViewer markdown={blurb} />
            <ButtonGroupMap buttons={buttons} />
        </div>
    )
}

Position.propTypes = {
    subheading: PropTypes.string.isRequired,
    heading: PropTypes.string.isRequired,
    blurb: PropTypes.string.isRequired,
    buttons: PropTypes.array.isRequired,
}